import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { StyledApp } from "./styled/StyledApp";
import ScrollToTop from "./hooks/ScrollToTop";
import Configuration from "./Configuration";

import HugoBossExperience from "./promo-ready/hugo-boss-experience/HugoBossExperience";

function App() {
  return (
    <>
      <Router>
        <Configuration />
        <ScrollToTop />
        <StyledApp>
          <Routes>
            <Route path="/" element={<HugoBossExperience />} />
            <Route path="*" element={<HugoBossExperience />} />
          </Routes>
        </StyledApp>
      </Router>
    </>
  );
}

export default App;
