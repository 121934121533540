import { configureStore } from "@reduxjs/toolkit";

import deviceReducer from "./features/devices/deviceSlice";
import balanceAndPinReducer from "./features/balance-and-pin/balanceAndPinSlice";
import hugoBossReducer from "./features/hugo-boss/hugoBossSlice";

export const store = configureStore({
  reducer: {
    devices: deviceReducer,
    balanceAndPin: balanceAndPinReducer,
    hugoBoss: hugoBossReducer
  }
});
